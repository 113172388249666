import { createSlice } from '@reduxjs/toolkit';
import { TulipModel } from '../../pages/tulips/Tulip';

const initialState: (TulipModel & { index: number })[] = [];

const basketSlice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        addItem(state, { payload }) {
            state.push(payload);
        },
        removeItem(state, { payload } ) {
            return state.filter(item => item.id !== payload.id);
        },
        clear(state) {
            return []
        }
    },
})

export const { addItem, removeItem, clear } = basketSlice.actions;

export default basketSlice.reducer;
