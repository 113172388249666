import React from 'react';
import css from './Delivery.module.scss';
import { ContentContainer } from '../../shared/ContentContainer';
import image from '../../assets/images/bus.jpeg'


export class Delivery extends React.Component {
    render() {
        return (
            <section className={ css.delivery }>
                <ContentContainer className={ css.container }>
                    <h2 className={ css.header } >Доставка</h2>
                    <div className={ css.content }>
                        <div className={ css.imageWrapper }>
                            <img className={ css.image } src={image} loading="lazy" alt="" />
                        </div>
                        <div className={ css.textWrapper }>
                            <h2 className={ css.headline }>
                                Доставка по всей Республике Беларусь
                            </h2>
                            <p className={ css.text }>
                                Доставим ваш товар в целости и сохранности в любую точку Республики Беларусь.
                                <br/>
                                <br/>
                                Доставка в Российскую Федерацию от 25 000 штук.
                                <br/>
                                <br/>
                                Доставка в Республику Казахстан от 100 000 штук.
                        </p>
                        </div>
                    </div>
                </ContentContainer>
            </section>
        )
    }
}
