import { combineReducers } from "redux";
import basket from '../features/basket/basetSlice';
import modal from '../shared/modalSlice';


export default combineReducers({
    basket,
    modal,
})

