import React from 'react';
import css from './Step.module.scss';

interface StepProps {
    image: any;
    desc?: string;
    name: string;
}

export const Step: React.FC<StepProps> = ({ image, name }) => {
    return (
        <div className={css.container}>
            <img alt="" className={css.image} loading="lazy" src={image} />
            <p className={css.name}>
                { name }
            </p>
        </div>
    );
};
