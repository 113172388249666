import { Link } from 'react-router-dom';
import css from './HeaderButton.module.scss';
import cx from 'classnames';
import React from 'react';
import { useMatch } from 'react-router-dom';

export interface HeaderButtonProps {
    href?: string;
    children?: React.ReactChild;
    to?: string;
    exact?: boolean;
    toggleBurgerIsOpen: () => void;
}

export function HeaderButton(props: HeaderButtonProps) {
    const { href, to, toggleBurgerIsOpen, children } = props;
    const match = useMatch({ path: to, end: true });
    return !href ? <Link
            rel="noopener"
            onClick={ toggleBurgerIsOpen }
            className={ css.headerButton }
            to={ to }
        >
            <div className={ cx(css.linkLabel, (match && css.activeLink)) }>
                { children }
            </div>
        </Link>
        :
        <a target="_blank" rel="noreferrer" className={ cx(css.linkLabel, css.headerButton) } href={ href }>{ children }</a>;
}
