import React from 'react';
import css from './HowToOrder.module.scss';
import { ContentContainer } from '../../../shared/ContentContainer';
import DeliveryImage from '../../../assets/images/delivery.png'
import ShopImage from '../../../assets/images/shop.png'
import ConversationImage from '../../../assets/images/conversation.png'
import PayImage from '../../../assets/images/pay.png'
import { Step } from './Step';

export function HowToOrder() {
    return (
        <section className={ css.container }>
            <ContentContainer>
                <h2 className={ css.header }>Порядок работы</h2>
                <div className={ css.content }>
                    <Step name="1. Заказ на сайте или по телефону" image={ShopImage}/>
                    <Step name="2. Согласование цены" image={ConversationImage}/>
                    <Step name="3. Предоплата" image={PayImage}/>
                    <Step name="4. Доставка" image={DeliveryImage}/>
                </div>
            </ContentContainer>
        </section>
    );
}
