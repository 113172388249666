import React from 'react';
import css from './Quality.module.scss';
import { ContentContainer } from '../../shared/ContentContainer';
import { Button } from '@mui/material';
import QualityImage from '../../assets/images/quality.png';

export class Quality extends React.Component {
    render() {
        return (
            <section className={css.about}>
                <ContentContainer className={css.container}>
                    <div className={css.flex}>
                        <div className={css.aboutTextWrapper}>
                            <h2 className={css.aboutTextTitle}>
                                Гарантия качества
                            </h2>
                            <p className={css.aboutText}>
                                Мы гарантируем качество тюльпанов на высшем уровне.
                                <br/>
                                <br/>
                                В любых вопросах мы принимаем сторону клиента, поэтому вернем вам деньги или обменяем некачественный товар.
                                <br/>
                                <br/>
                            </p>
                            <div className={css.image}>
                                <img alt="" src={QualityImage} loading="lazy" className={css.aboutImage}/>
                            </div>
                            <Button className={css.button}  href="/tulips" size="large" style={{ marginTop: 'auto' }} variant="contained"  color="secondary">Посмотреть каталог</Button>
                        </div>
                        <div className={css.aboutImageWrapper}>
                            <img alt="" src={QualityImage} loading="lazy" className={css.aboutImage}/>
                        </div>
                    </div>
                </ContentContainer>
            </section>
        )
    }
}
