import 'normalize.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container } from './Container';

const theme = createTheme({
    palette: {

        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#4caf50',
            contrastText: 'white',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#af4cab',
            // dark: will be calculated from palette.secondary.main,
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    spacing: [0, 6, 12, 18, 24, 32],
});
export class App extends React.Component {
    render() {
        return (
            <ThemeProvider theme={ theme }>
                <Router >
                    <Container/>
                </Router>
            </ThemeProvider>
        );
    }
}
