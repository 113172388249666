import React from 'react';
import css from './Contacts.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faViber, faWhatsappSquare, faSkype, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { ContentContainer } from '../../shared/ContentContainer';

export class Contacts extends React.Component {
    render() {
        return (
            <section className={ css.contacts }>
                <ContentContainer className={ css.container }>
                    <h2 className={ css.contactsHeader } >Контакты</h2>
                    <div className={ css.contactsWrapper }>
                        <div className={ css.communication }>
                            <div className={ css.communicationContent }>
                                <h4>Телефон</h4>
                                <a href="tel:+375296563803">+375 (29) 6563803 (Виталий)</a>
                                <h4>Email</h4>
                                <a href="mailto:ignashevich@tut.by">ignashevich@tut.by</a>
                                <h4>Время работы</h4>
                                <p>08:00 - 22:00 (Пинск)</p>
                                <h4>Нажмите чтобы связаться</h4>
                                <div className={ css.communicationSupport }>
                                    <a href="viber://contact?number=%2B375296563803">
                                        <FontAwesomeIcon icon={ faViber } />
                                    </a>
                                    <a href="whatsapp://send?phone=+375296563803" data-action="share/whatsapp/share">
                                        <FontAwesomeIcon icon={ faWhatsappSquare } />
                                    </a>
                                    <a href="skype:ignashevich3?chat">
                                        <FontAwesomeIcon icon={ faSkype } />
                                    </a>
                                    <a href="tg://resolve?domain=ignashevichvk">
                                        <FontAwesomeIcon icon={ faTelegram } />
                                    </a>
                                </div>


                            </div>
                        </div>
                        <div className={ css.location }>
                            <div className={ css.locationContent }>
                                <address className={ css.locationAdress }>
                                    <p>г. Пинск, ул. Богдановича 57</p>
                                </address>
                                <iframe
                                    className={ css.map }
                                    frameBorder="0" style={ { border: 0 } }
                                    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJcbgh7sCgJ0cRkIrqXHK-i7c&key=AIzaSyB0mv_p8YbfSYl_eYWi3et7_pK26g587x8"
                                    allowFullScreen
                                    loading="lazy"
                                    >
                                </iframe>
                            </div>
                        </div>
                    </div>
                </ContentContainer>
            </section>
        )
    }
}
