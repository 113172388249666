import React from 'react';
import { useSelector } from 'react-redux';
import { HeaderButton, HeaderButtonProps } from './HeaderButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import css from './BasketButton.module.scss';
import { RootState } from '../types';
import cx from "classnames";

export const BasketButton: React.FC<HeaderButtonProps> = (props) => {
    const itemsCount = useSelector((state: RootState) => state.basket.length);

    return (
        <HeaderButton { ...props }>
            <div className={ cx(css.container, itemsCount > 0 && css.animation) }>
                <FontAwesomeIcon icon={ faShoppingCart }/>
                <div className={ css.count }>{ itemsCount }</div>
            </div>
        </HeaderButton>
    );
};
