import React from 'react'
import css from './ContactsHeader.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faViber, faWhatsappSquare, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { ContentContainer } from './ContentContainer';


export class ContactsHeader extends React.Component {
    render() {
        return (
            <div className={ css.contactsHeaderContainer }>
                <ContentContainer className={ css.contactsHeader }>
                    <a className={ css.phoneNumber } href="tel:+375296563803"><FontAwesomeIcon icon={ faPhone } />+375 (29) 6563803</a>
                    <div className={ css.contactsIconsWrapper }>
                        <a href="viber://contact?number=%2B375296563803">
                            <FontAwesomeIcon icon={ faViber } />
                        </a>
                        <a href="whatsapp://send?phone=+375296563803" data-action="share/whatsapp/share">
                            <FontAwesomeIcon icon={ faWhatsappSquare } />
                        </a>
                        <a href="tg://resolve?domain=ignashevichvk">
                            <FontAwesomeIcon icon={ faTelegram } />
                        </a>
                        {/* <a href="tg://resolve?domain=ignashevichvk">
                            <FontAwesomeIcon icon={ faTelegram } />
                        </a> */}
                    </div>
                </ContentContainer>
            </div>
        )
    }
}
