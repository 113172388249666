/* eslint-disable import/no-anonymous-default-export */
export default [{
    "id": "alibi",
    "color": "Светло-фиолетовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2FAlibi.jpeg?alt=media&token=42dbadc7-ab66-461d-b406-9dd53bdeeaff",
    "height": "50-55см",
    "isOutOfStock": true,
    "isVisible": false,
    "name": "Alibi",
    "price": "0.5$"
},
{
    "id": "antartica",
    "color": "Белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fantartica.jpg?alt=media&token=84ec200b-c9a8-4ae6-9677-691be42bdf31",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Antartica",
    "price": "0.5$"
},
{
    "id": "barre-alta",
    "color": "Розовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fbarre-alta.jpg?alt=media&token=8c15cdaa-102c-42eb-aac3-8b38dc20aeda&_gl=1*8o3n7u*_ga*MjAxNDcyMjg4LjE2OTc2NzExMjk.*_ga_CW55HF8NVT*MTY5ODA4MjM4My4xLjEuMTY5ODA4MjcwOS42MC4wLjA.",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Barre Alta",
    "price": "0.5$"
},
{
    "id": "buster",
    "color": "Красный-белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fbuster.jpg?alt=media&token=25cfccde-d157-4a4c-9830-f66e68ca89d8&_gl=1*1jbfiz4*_ga*MjAxNDcyMjg4LjE2OTc2NzExMjk.*_ga_CW55HF8NVT*MTY5ODA4MjM4My4xLjEuMTY5ODA4NDA3OC42MC4wLjA.",
    "height": "50-55см",
    "isOutOfStock": true,
    "isVisible": true,
    "name": "Buster",
    "price": "0.5$"
},
{
    "id": "cash",
    "color": "Красно-желтый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fcash.jpg?alt=media&token=fa82efbf-52fa-4bb4-ba7b-82ddd76b4512",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Cash",
    "price": "0.5$"
},
{
    "id": "columbus",
    "color": "Розово-белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fcolumbus.png?alt=media&token=a82d834f-f68d-4399-bbad-8b2f4972e5ee",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Columbus",
    "price": "0.5$"
},
{
    "id": "cheers",
    "color": "Бежевый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fcheers.jpg?alt=media&token=38f1358f-74c9-41b3-b2d0-d2991deadcd9",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Cheers",
    "price": "0.5$"
},
{
    "id": "denmark",
    "color": "Красный/желтый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2FDenmark.jpg?alt=media&token=19df5cb5-f70c-4afe-bfc7-657d87dbceb4",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Denmark",
    "price": "0.5$"
}, {
    "id": "dow-jones",
    "color": "Красно-желтый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fdow-jones.jpg?alt=media&token=440fb4d0-e4f6-4303-b85b-f6ab23f6a1e6",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Dow Jones",
    "price": "0.5$"
}, {
    "id": "dynasty",
    "color": "Розовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fdynasty-2.jpeg?alt=media&token=c5abc699-ba7c-4040-a55c-624d0bd311c3",
    "height": "45-55см",
    "isVisible": true,
    "isOutOfStock": true,
    "name": "Dynasty",
    "price": "0.5$"
}, 
{
    "id": "fabio",
    "color": "Красно-желтый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Ffabio.jpg?alt=media&token=bbb41c33-2d80-45b3-9f78-ece6ac3344d4&_gl=1*1tphnma*_ga*MjAxNDcyMjg4LjE2OTc2NzExMjk.*_ga_CW55HF8NVT*MTY5ODA4MjM4My4xLjEuMTY5ODA4Mjc4MS41OC4wLjA.",
    "height": "40-45см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Fabio",
    "price": "0.5$"
},
{
    "id": "first-star",
    "color": "Красный",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Ffirst-star.jpg?alt=media&token=cf9cac17-6759-4c8c-bd53-c80532d0f7f2",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "First star",
    "price": "0.5$"
}, {
    "id": "flaming-flag",
    "color": "Бело-малиновый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fflamingo-flag.jpg?alt=media&token=e7c4d111-6c3c-4f75-9672-912fb52d0d90",
    "height": "40-45см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Flaming Flag",
    "price": "0.5$"
},
{
    "id": "fun-for-two",
    "color": "Бежевый",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Fun For Two",
    "price": "0.5$",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Ffun-for-two.jpg?alt=media&token=c1499b1c-794e-482b-ac53-3ba369bb2e11"
},
{
    "id": "gabriella",
    "color": "Розовый",
    "height": "45-50см",
    "isOutOfStock": true,
    "isVisible": false,
    "name": "Gabriella",
    "price": "0.5$",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2FGabriella.jpeg?alt=media&token=a667f413-f20c-4914-87c9-c8f9756fd9b9"
},

{
    "id": "gomera",
    "color": "Фиолетовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fgomera.jpg?alt=media&token=0a1e691c-1d0a-4666-90cb-4c8b705a935b",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Gomera",
    "price": "0.5$"
}, {
    "id": "jumbo-pink",
    "color": "Розовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fjumbo-pink.jpg?alt=media&token=10e2e78d-22d6-4643-b134-9907675195e8",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Jumbo Pink",
    "price": "0.5$"
},
{
    "id": "kamaliya",
    "color": "Розово-белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fkamalyia.jpg?alt=media&token=d9f25f97-7982-45d7-9af2-33eb4ad5a8d6&_gl=1*1cu9mcl*_ga*MjAxNDcyMjg4LjE2OTc2NzExMjk.*_ga_CW55HF8NVT*MTY5ODA4MjM4My4xLjEuMTY5ODA4Mjg1Ni42MC4wLjA.",
    "height": "45-50см",
    "isOutOfStock": true,
    "isVisible": true,
    "name": "Kamaliya",
    "price": "0.5$"
},
{
    "id": "kobla",
    "color": "Белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2FKobla.jpeg?alt=media&token=4d7511fb-34af-410b-a5c7-cf56adaee70c",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Kobla",
    "price": "0.5$"
},
{
    "id": "kung-fu",
    "color": "Красный/белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fkung-fu.jpg?alt=media&token=5104c0b1-7c19-437d-915a-a61955cd11f1",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Kung Fu",
    "price": "0.5$"
}, {
    "id": "killing-love",
    "color": "Красный",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2FKilling-love.jpeg?alt=media&token=5e5ce74c-37dc-452a-a9d7-60b0732782fa",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Killing love",
    "price": "0.5$"
}, {
    "id": "lalibela",
    "color": "Красный",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Flalibela.jpg?alt=media&token=6adc05c2-314e-4f5c-81d3-7e3e36b171b4",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Lalibela",
    "price": "0.5$"
}, {
    "id": "laptop",
    "color": "Фиолетовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Flaptop-2.jpeg?alt=media&token=26dda9f3-b2b9-448f-8c4e-e6bdce64da62",
    "height": "50-55см",
    "isOutOfStock": true,
    "isVisible": true,
    "name": "Laptop",
    "price": "0.5$"
}, {
    "id": "lech-walesa",
    "color": "Розовый/белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Flech-walesa-2.jpeg?alt=media&token=830ccc2e-dcd5-4f35-9214-828598a33e0e",
    "height": "50-55см",
    "isOutOfStock": true,
    "isVisible": true,
    "name": "Lech Walesa",
    "price": "0.5$"
}, {
    "id": "leen-van-der-mark",
    "color": "Красный/белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fleen-van-de-mark.jpg?alt=media&token=ef91ac8a-2f87-4e77-bc90-3c425bf7acd4",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Leen van der Mark",
    "price": "0.5$"
},
{
    "id": "liliane-gronert",
    "color": "Розовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fliliane-gronert.jpg?alt=media&token=552f5341-1b1e-4e67-96da-a0574880ea87&_gl=1*1hzqd3e*_ga*MjAxNDcyMjg4LjE2OTc2NzExMjk.*_ga_CW55HF8NVT*MTY5ODA4MjM4My4xLjEuMTY5ODA4MjkyMi42MC4wLjA.",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Liliane Gronert",
    "price": "0.5$"
},

{
    "id": "margarita",
    "color": "Фиолетовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fmargarita.jpeg?alt=media&token=a8f7c1c7-e801-4426-a4a4-ef5e53025533",
    "height": "45-50см",
    "isOutOfStock": true,
    "isVisible": true,
    "name": "Margarita",
    "price": "0.5$"
}, 
{
    "id": "match",
    "color": "Желто-малиновый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fmatch.jpg?alt=media&token=8c69b09e-53c9-45b1-9efc-9efb4a39c7b0&_gl=1*1ncnuk4*_ga*MjAxNDcyMjg4LjE2OTc2NzExMjk.*_ga_CW55HF8NVT*MTY5ODA4MjM4My4xLjEuMTY5ODA4Mjk2OC4xNC4wLjA.",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Match",
    "price": "0.5$"
},
{
    "id": "matchmaker",
    "color": "Розовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2FMatchmaker.jpeg?alt=media&token=fe7cbba5-6428-4bd5-9323-8ec98e72fd28",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Matchmaker",
    "price": "0.5$"
}, 
{
    "id": "memphis",
    "color": "Розово-белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fmemphis.jpg?alt=media&token=94f30ef9-9e49-4d02-bf7e-e95d54b9a61a&_gl=1*13suz4w*_ga*MjAxNDcyMjg4LjE2OTc2NzExMjk.*_ga_CW55HF8NVT*MTY5ODA4MjM4My4xLjEuMTY5ODA4MzAwOC42MC4wLjA.",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Memphis",
    "price": "0.5$"
},


{
    "id": "ozon",
    "color": "фиолетовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2FOzon.jpeg?alt=media&token=ae146df5-ec6f-41c5-8c8c-35e73e938c2d",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Ozon",
    "price": "0.5$"
},
{
    "id": "pink-ardour",
    "color": "Малиновый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2FPink-ardour.jpeg?alt=media&token=5d6941e3-bc31-45f0-abb2-0ede68b90596",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Pink ardour",
    "price": "0.5$"
},
{
    "id": "powerplay",
    "color": "Красно-желтый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fpowerplay.jpg?alt=media&token=eb04edaa-32a9-41de-bcae-a9e9e4385e32",
    "height": "50-55см",
    "isOutOfStock": true,
    "isVisible": true,
    "name": "Powerplay",
    "price": "0.5$"
}, {
    "id": "purple-flag",
    "color": "Фиолетовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fpurple-flag.jpg?alt=media&token=5dfd47f4-a0c0-4930-9545-b34388d8b7dc",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Purple Flag",
    "price": "0.5$"
}, 
{
    "id": "red-dragon",
    "color": "Красный",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fred-dragon-2.jpeg?alt=media&token=9321ac32-7762-4885-8822-c6dee8d5cba7",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Red Dragon",
    "price": "0.5$"
},
{
    "id": "royal-virgin",
    "color": "Белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Froyal-virgin.jpeg?alt=media&token=7296db45-e0e1-488a-9a81-22fd71d5e22d",
    "height": "45-50см",
    "isOutOfStock": true,
    "isVisible": true,
    "name": "Royal Virgin",
    "price": "0.5$"
}, {
    "id": "striped-flag",
    "color": "Фиолетово-белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fstriped-flag.jpg?alt=media&token=01e40b61-1671-49ad-87b8-b63ddaecdf90",
    "height": "40-45см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Striped Flag",
    "price": "0.5$"
}, {
    "id": "strong-gold",
    "color": "Желтый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fstrong-gold.jpg?alt=media&token=c6311994-323b-49a3-ab5f-a8d294eb08cc",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Strong Gold",
    "price": "0.5$"
}, {
    "id": "strong-love",
    "className": "strong-love",
    "color": "Бордовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fstrong-love-2.jpg?alt=media&token=889bc7f9-0726-45d0-bf4b-e6a86467f670",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Strong Love",
    "price": "0.5$"
}, {
    "id": "strong-power",
    "color": "Красный",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fstrongpower.jpg?alt=media&token=500cd80d-c25e-48fd-b569-a936a3fc4f9d",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Strong Power",
    "price": "0.5$"
}, {
    "id": "super-model",
    "color": "Розово-белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fsuper-model.jpeg?alt=media&token=dd199638-2130-4664-b29d-9677931ce731",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Super Model",
    "price": "0.5$"
}, {
    "id": "surrender",
    "color": "Красный",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fsurrender.jpg?alt=media&token=57f8af2a-619f-4fbf-99d1-26278224d196",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Surrender",
    "price": "0.5$"
},
{
    "id": "tom-pouce",
    "color": "Розово-желтый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Ftom-pounce.jpg?alt=media&token=9629b768-3a3a-4b1e-abad-63953649716b",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Tom Pouce",
    "price": "0.5$"
},
{
    "id": "trailblazer",
    "color": "Красно-желтый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fdow-jones.jpg?alt=media&token=440fb4d0-e4f6-4303-b85b-f6ab23f6a1e6",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Trailblazer",
    "price": "0.5$"
},
{
    "id": "tineke-vd-meer",
    "color": "Розовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Ftineke-vd-meer-2.jpeg?alt=media&token=8a5183b0-c7c8-425a-a340-f7edd87c5373",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Tineke Vd Meer",
    "price": "0.5$"
}, {
    "id": "trick",
    "color": "Розовый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Ftrick.jpg?alt=media&token=6f5d9cda-464a-4e9a-9512-92e52f2a8434",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": false,
    "name": "Trick",
    "price": "0.5$"
}, {
    "id": "triple-a",
    "color": "Оранжевый/желтый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Ftriple-a.jpg?alt=media&token=1cab6ec2-a2d7-4512-a181-cd4a1b4dbd5d",
    "height": "50-55см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Triple A",
    "price": "0.5$"
},

{
    "id": "verandi",
    "color": "Красный/желтый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fverandi-2.jpeg?alt=media&token=87f7df4f-5083-43cd-9cd5-8b0d473d9c6f",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "Verandi",
    "price": "0.5$"
},
{
    "id": "white-flag",
    "color": "Белый",
    "img": "https://firebasestorage.googleapis.com/v0/b/tulpan-mart-1526219658606.appspot.com/o/tulips%2Fwhite-flag.jpg?alt=media&token=91a992bb-0e5c-4817-b3fa-b6af89f3afd0&_gl=1*1m2r95p*_ga*MjAxNDcyMjg4LjE2OTc2NzExMjk.*_ga_CW55HF8NVT*MTY5ODA4MjM4My4xLjEuMTY5ODA4MzA5OC42MC4wLjA.",
    "height": "45-50см",
    "isOutOfStock": false,
    "isVisible": true,
    "name": "White Flag",
    "price": "0.5$"
},
]
