import { createStore } from 'redux';
import rootReducer from './reducers';
import data from './data/tulips';

export const loadState = () => {
    const serializedState = localStorage.getItem('state1');
    if (serializedState === null) {
        return undefined;
    }
    const state = JSON.parse(serializedState);
    state.basket = state.basket.map(item => ({ index: item.index, ...data.find(d => d.id === item.id) })).filter(item => !item.isOutOfStock && item.isVisible);
    localStorage.setItem('state1', JSON.stringify(state));
    const form = JSON.parse(localStorage.getItem('form2'));

    if(form) {
        Object.keys(form.order).forEach(key => {
            if (!state.basket.find(item => key.includes(item.name))) {
                delete form.order[key];
            }
        });
        localStorage.setItem('form2', JSON.stringify(form))
    }


    return state;
};

export const saveState = (state) => {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state1', serializedState);
};

export const store = createStore(rootReducer, loadState(), (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());

store.subscribe(() => {
    saveState({
        basket: store.getState().basket,
    });
});
