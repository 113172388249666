import React from 'react';
import css from './Loading.module.scss';

interface LoadingProps {

}

export const Loading: React.FC<LoadingProps> = (props) => {
    return (
        <div className={ css.loader }>
            <div className={ css.loaderBar }></div>
        </div>
    );
};
