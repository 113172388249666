import React, { useEffect, Suspense } from 'react';
import css from './Container.module.scss';
import { Header } from './shared/Header';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Footer } from './shared/Footer';
import { Loading } from './shared/Loading';
import Main from './pages/main/Main';

const Order = React.lazy(() => import('./pages/order/Order'));
const Gallery = React.lazy(() => import('./pages/gallery/Gallery'));
const Tulips = React.lazy(() => import('./pages/tulips/Tulips'));

interface ContainerProps {

}

export const Container: React.FC<ContainerProps> = (props) => {
    let location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className={ css.container }>
            <Header/>
            <main className={ css.main }>
                <Suspense fallback={ <Loading/> }>
                    <Routes>
                        <Route path="/" element={ <Main/> }/>
                        <Route path="/gallery" element={ <Gallery/> }/>
                        <Route path="/tulips" element={ <Tulips/> }/>
                        <Route path="/order" element={ <Order/> }/>
                        <Route element={ <Main/> }/>
                    </Routes>
                </Suspense>
            </main>
            <Footer/>
        </div>
    );
};
