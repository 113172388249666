import React from 'react';
import css from './ContentContainer.module.scss';
import cx from 'classnames';

interface ContentContainerProps {
    className?: string;
}

export class ContentContainer extends React.Component<ContentContainerProps> {
    render() {
        return <div className={cx(this.props.className, css.container)}>
                { this.props.children }
            </div>
    
    }
}