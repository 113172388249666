import React from 'react';
import css from './Header.module.scss';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { ContactsHeader } from './ContactsHeader';
import { ContentContainer } from './ContentContainer';
import { HeaderButton } from './HeaderButton';
import { BasketButton } from './BasketButton';

interface HeaderState {
    burgerIsOpen: boolean;
}



function BurgerIcon({ toggleBurgerIsOpen, burgerIsOpen }: any) {
    return <div className={cx(css.burgerIcon, burgerIsOpen && css.burgerIsOpen)} onClick={toggleBurgerIsOpen}>
        <div className={css.burgerLabel}>
            Меню
        </div>
        <div>
            <div className={css.bar1}></div>
            <div className={css.bar2}></div>
            <div className={css.bar3}></div>
        </div>
    </div>
}

export class Header extends React.Component<any, HeaderState> {

    constructor(props: any) {
        super(props);
        this.state = {
            burgerIsOpen: false,
        };
        this.toggleBurgerIsOpen = this.toggleBurgerIsOpen.bind(this);
    }

    toggleBurgerIsOpen() {
        if (window.innerWidth <= 820) {
            this.setState({ burgerIsOpen: !this.state.burgerIsOpen });
        }
    }

    render() {
        return (
            <header className={cx(css.header, this.state.burgerIsOpen && 'responsive')}>
                <ContactsHeader />
                <ContentContainer>
                    <nav className={css.navigation}>
                        <Link to='/' className={css.logo}>tulpan-mart.by</Link>
                        <HeaderButton toggleBurgerIsOpen={this.toggleBurgerIsOpen} exact to='/' >О Нас</HeaderButton>
                        <HeaderButton toggleBurgerIsOpen={this.toggleBurgerIsOpen} to='/tulips'>Продукция</HeaderButton>
                        <HeaderButton toggleBurgerIsOpen={this.toggleBurgerIsOpen} to='/gallery'>Фото</HeaderButton>
                        <HeaderButton toggleBurgerIsOpen={this.toggleBurgerIsOpen} to='/123' href='https://www.google.com/search?q=tulpan-mart.by&oq=tulpan-mart.by&aqs=chrome..69i58j69i57j69i60l3.3241j0j7&sourceid=chrome&ie=UTF-8#lrd=0x4727a0c0ee21b871:0xb78bbe725cea8a90,1,,,'>Отзывы</HeaderButton>
                        <BasketButton toggleBurgerIsOpen={this.toggleBurgerIsOpen} to='/order'/>
                        <BurgerIcon burgerIsOpen={this.state.burgerIsOpen} toggleBurgerIsOpen={this.toggleBurgerIsOpen} />
                    </nav>
                </ContentContainer>
            </header>
        )
    }
}
