import React from 'react';
import css from './About.module.scss';
import { ContentContainer } from '../../shared/ContentContainer';
import { Button } from '@mui/material';
import image from '../../assets/images/about-image.jpeg';

export class About extends React.Component {
    render() {
        return (
            <section className={css.about}>
                <ContentContainer className={css.container}>
                    <div className={css.aboutTextWrapper}>
                        <h2 className={css.aboutTextTitle}>
                            Мы предлагаем
                    </h2>
                        <ol className={css.aboutText}>
                            <li>Отличный сервис и первоклассные цветы собственного производства</li>
                            <br />
                            <li>Качественные тюльпаны крупным оптом с доставкой по РФ и РБ. Крупным клиентам скидки</li>
                            <br />
                            <li>Консультация по любому возникшему у Вас вопросу. Также, по согласованию с Вами,
                                проведем предпродажную подготовку цветов</li>
                            <br />
                            <br />
                            <Button href="/gallery" size="large" style={{ marginTop: 12 }} variant="contained"  color="secondary">Посмотреть фото</Button>
                </ol>
                    </div>
                    <div className={css.aboutImageWrapper}>
                        <img className={css.aboutImage} loading="lazy" src={image} alt="" />
                    </div>
                </ContentContainer>
            </section>
        )
    }
}
