import { createSlice } from '@reduxjs/toolkit';

const initialState: { isOpen: boolean, content: string }= { isOpen: false, content: ''};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal(state, { payload }) {
            state.isOpen = true;
            state.content = payload.content;
        },
        closeModal(state ) {
            state.isOpen = false;
            state.content = '';
        },
    },
})

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
